import lozad from 'lozad';
// import posthog from 'posthog-js';
import '@fontsource/gentium-book-basic/latin-700.css'; // Weight 700.

// gatsby-browser.js
const { observe } = lozad('.lozad', {
  loaded: (el) => {
    const { objectFit: finalObjectFit } = el.dataset;

    /**
     * TODO：
     * 这里是为了解决loading占位图不会适应，铺满所有尺寸的图片的问题，
     * 可以先将loading图的object-fit设置为cover,
     * 后面通过设置data-object-fit设置最终图片的object-fit
     */
    if (el.nodeName === 'IMG' && el instanceof HTMLImageElement) {
      el.onload = () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      };
    } else if (el.nodeName === 'VIDEO' && el instanceof HTMLVideoElement) {
      el.addEventListener('loadeddata', () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      });
    } else {
      el.classList.add('fade');
    }
  },
});

// export const onClientEntry = () => {
//   posthog.init('phc_gb7wFAZcgxaVInjbSsfv9A9fE8trrn1SuCVDCtNuR2Z', {
//     api_host: 'https://us.i.posthog.com', // 如果是自托管，请改为你的 PostHog 实例地址
//     cross_subdomain_cookie: true,
//   });
// };

export const onInitialClientRender = () => {};

export const onRouteUpdate = ({ location }) => {
  observe();
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;

  const excludeRoute = ['/en/changelog'];

  if (excludeRoute.indexOf(pathname) !== -1) {
    return false;
  }

  return true;
};
